import "./DesktopNavbar.scss"
import { useState, useEffect } from "react"
import { TypeAnimation } from "react-type-animation"

const DesktopNavbar = () => {

  const [atTop, setAtTop] = useState(true)

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
  
    useEffect(() => {
      let lastScrollY = window.pageYOffset;
  
      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
        window.scrollY > 10 ? setAtTop(true) : setAtTop(false)
      };
      window.addEventListener("scroll", updateScrollDirection);
      return () => {
        window.removeEventListener("scroll", updateScrollDirection);
      }
    }, [scrollDirection]);
  
    return scrollDirection;
  };

  const scrollDirection = useScrollDirection()

  return (
    <nav className={`desktop-navbar ${scrollDirection === "down" ? " hide" : " show"} ${atTop ? " shadow" : " no-shadow"}`}>
      <div className="name-wrapper">
        <TypeAnimation
          sequence={[
            500,
            'nolan_roth'
          ]}
          wrapper="span"
          cursor={true}
          speed={200}
    />
      </div>
      <div className="nav-links">
        <a href="#about">/about</a>
        <a href="#experience">/experience</a>
        <a href="#projects">/projects</a>
        <a href={process.env.PUBLIC_URL + '/nolan_roth_resume.pdf'}>/resum&#233;</a>
      </div>
    </nav>
  )
}

export default DesktopNavbar