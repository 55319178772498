import './MobileNavbar.scss'
import { useState } from 'react'
import { TypeAnimation } from 'react-type-animation'

const MobileNavbar = () => {
  const [burgerClass, setBurgerClass] = useState("burger-bar unclicked")
  const [menuClass, setMenuClass] = useState("menu hidden")
  const [isMenuClicked, setIsMenuClicked] = useState(false)

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("burger-bar clicked")
      setMenuClass("menu visible")
    } else {
      setBurgerClass("burger-bar unclicked")
      setMenuClass("menu hidden")
    }
    setIsMenuClicked(!isMenuClicked)
  }

  return (
    <nav className="mobile-navbar">
      <div className='mobile-navbar-inner'>
        <div className="name-wrapper">
          <TypeAnimation
            sequence={[
              500,
              'nolan_roth'
            ]}
            wrapper="span"
            cursor={true}
            speed={200}
          />
        </div>
        <div className='burger-menu' onClick={updateMenu}>
          <div className={burgerClass}></div>
          <div className={burgerClass}></div>
          <div className={burgerClass}></div>
        </div>
      </div>
      <div className={menuClass} onClick={updateMenu}>
        <a className='mobile-nav-link' href="#about">/about</a>
        <a className='mobile-nav-link' href="#experience">/experience</a>
        <a className='mobile-nav-link' href="#projects">/projects</a>
      </div>
    </nav>
  )
}

export default MobileNavbar