import "./Experience.scss"
import { FaAngleRight } from "react-icons/fa"

const Experience = () => {
  return (
    <div className="experience" id="experience">
      <div className="experience-inner">
        <h2>Experience</h2>
        <div className="description">
          <h3>Software Development Engineer <span>@</span> <a href="https://www.linkedin.com/company/amazon/" target="_blank" >Amazon</a></h3>
          <p className="date-range">August 2022 - March 2023</p>
          <p>Developed backend Java software for Amazon Grocery Delivery on Amazon.com. Team 
            responsibility included a number of services responsible for the orchestration and 
            fulfillment of order procurement and delivery to customer.
          </p>
          <ul>
            <li><FaAngleRight className="arrow" />
              Collaborated with other engineers and teams to improve the flexibility of the system responsible for delivery
              rescheduling, leading to a significant decrease in total order cancellations
            </li>
            <li><FaAngleRight className="arrow" />
              Improved system maintainability by writing integration tests and implementing chaos testing for a service
            </li>
            <li><FaAngleRight className="arrow" />
              Led a design proposal, including writing a document, for identifying sources of information for new KPIs
            </li>
            <li><FaAngleRight className="arrow" />
              Diagnosed issues and identified customer impact utilizing AWS CloudWatch, SQS DLQs, and server logs
            </li>
          </ul>
        </div>
        <div className="description">
          <h3>Software Engineering Intern <span>@</span> <a href="https://www.linkedin.com/company/relaymile/" target="_blank">Relaymile</a></h3>
          <p className="date-range">January 2022 - April 2022</p>
          <p>Developed backend and fronted software for the web application of a logistics 
            startup.
          </p>
          <ul>
            <li><FaAngleRight className="arrow" />Added new functionality and improved backend Restful APIs with Flask and PostgreSQL</li>
            <li><FaAngleRight className="arrow" />Architected and led development of a feature that allowed our clients to store, 
              view, and share important documents</li>
            <li><FaAngleRight className="arrow" />Deployed codebase to development and production server instances hosted with AWS EC2, 
              including identifying and debugging production server issues</li>
            <li><FaAngleRight className="arrow" />Designed solutions while working closely with management to resolve decisions involving 
              technical trade-offs</li>
            <li><FaAngleRight className="arrow" />Created and improved React frontend components when additional help was required</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Experience