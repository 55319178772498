import React from "react"
import "./About.scss"
import profile_small from "../../static/profile_small.png"
import { FaAngleRight } from "react-icons/fa"
import { FaPython, FaReact, FaJava, FaAws } from "react-icons/fa"
import { SiJavascript } from "react-icons/si"

const About = () => {
  return (
    <div className="about" id="about">
      <div className="about-inner">
        <div className="info-and-img">
          <h2>About</h2>
          <div className="info-wrapper">
            <div className="info">
              <p>I began
                building web applications during my senior year of college, while completing my <strong>B.S. in Physics</strong>.
                at the <strong>University of Washington</strong>. After building a few projects, I was hooked, and decided that
                I would fully pursue software development. After I graduated, I completed my first internship at <strong>Relaymile</strong>,
                followed by my first full-time position at <strong>Amazon</strong>.
              </p>
              <p>
                In addition to my software engineering skills and physics degree, I have minors in <strong>Math</strong>, and
                <strong> Applied Math</strong>. While the majority of my work experience is as a <strong>back-end developer</strong>,
                I enjoy building on the <strong>front-end</strong> as well.
              </p>
              <p className="technologies-label">The technologies I use the most are:</p>
              <div className="icons-wrapper">
                <p><FaPython className="icon" /> Python</p>
                <p><SiJavascript className="icon" /> JavaScript</p>
                <p><FaReact className="icon" /> React</p>
                <p><FaAws className="icon" />AWS</p>
                <p><FaJava className="icon" />Java</p>
              </div>
            </div>
            <div className="profile-img-wrapper">
              <img className="profile-img" src={profile_small} alt="picture-of-me" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About