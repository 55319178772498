import MobileNavbar from "../MobileNavbar/MobileNavbar"
import DesktopNavbar from "../DesktopNavbar/DesktopNavbar"

const Navbar = () => {
  return (
    <>
      <DesktopNavbar />
      <MobileNavbar />
    </>
  )
}

export default Navbar