import "./Hero.scss"
import { FaGithub, FaLinkedin } from "react-icons/fa"

const Hero = () => {

  return (
    <div className="hero">
      <div className="hero-inner">
        <h1>Nolan Roth</h1>
        <h2>Software Engineer</h2>
        <h3>Hello, I am a software engineer who specializes in web development.
        </h3>
        <div className="icon-wrapper">
          <a href="https://github.com/n-roth12" target="_blank"><FaGithub className="icon" /></a>
          <a href="https://www.linkedin.com/in/nolan-roth/" target="_blank" ><FaLinkedin className="icon" /></a>
        </div>
      </div>
    </div>
  )
}

export default Hero