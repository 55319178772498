import "./Projects.scss"
import mainslater from "../../static/mainslater.png"
import draftabl from '../../static/draftabl.png'
import { FaArrowRight } from "react-icons/fa"

const Projects = () => {
  return (
    <div className='projects' id="projects">
      <div className='projects-inner'>
        <h2>Projects</h2>
        <div className='project'>
          <div className="info">
            <a href="https://mainslater.com">Mainslater <FaArrowRight className="arrow" /></a>
            <p className="project-description">A web application for creating NFL daily fantasy sports lineups across different 
              contest providers. Export lineups so that they can be easily uploaded to others sites, 
              or import your past lineups to examine past performance.
            </p>
            <div className="project-tools">
              <ul>
                <li>React</li>
                <li>JavaScript</li>
                <li>Python</li>
                <li>Flask</li>
                <li>AWS</li>
              </ul>
            </div>
          </div>
          <div className="test mainslater-image">
            <a href="https://mainslater.com">
              <img src={mainslater}></img>
            </a>
          </div>
        </div>
        <div className='project'>
          <div className="info">
            <a href="https://draftabl.com">Draftabl <FaArrowRight className="arrow" /></a>
            <p className="project-description">A web application that allows users to view, create, 
            and customize fantasy football rankings cheat sheets for their drafts.</p>
            <div className="project-tools">
              <ul>
                <li>React</li>
                <li>Redux</li>
                <li>Express</li>
                <li>Node</li>
                <li>MongoDB</li>
                <li>AWS</li>
              </ul>
            </div>
          </div>
          <div className="test draftabl-image">
            <a href="https://draftabl.com">
              <img src={draftabl}></img>
              </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Projects